import firebase from 'firebase/app'
import { Module } from 'vuex'
import { RootState } from '..'


export interface RolesState {
  unassignOthersFromBatches: boolean
  viewAdminReports: boolean
  unlockReadonlyBatches: boolean
}

enum mutations {
  UNASSIGN_OTHERS_FROM_BATCHES = 'UNASSIGN_OTHERS_FROM_BATCHES',
  VIEW_ADMIN_REPORTS = 'VIEW_ADMIN_REPORTS',
  UNLOCK_READONLY_BATCHES = 'UNLOCK_READONLY_BATCHES'
}

export const roles: Module<RolesState, RootState> = {
  namespaced: true,
  state: {
    unassignOthersFromBatches: false,
    viewAdminReports: false,
    unlockReadonlyBatches: false
  },
  getters: {
    ref: (state, getters, rootState, rootGetters) => rootGetters['profile/collection'].doc('roles')
  },
  actions: {
    async init ({ commit, getters }) {
      const snapshot = await getters.ref.get() as firebase.firestore.DocumentSnapshot
      if (snapshot.exists) {
        const roles = snapshot.data() as Partial<RolesState>
        if (roles.unassignOthersFromBatches !== undefined) {
          commit(mutations.UNASSIGN_OTHERS_FROM_BATCHES, roles.unassignOthersFromBatches)
        }
        if (roles.viewAdminReports !== undefined) {
          commit(mutations.VIEW_ADMIN_REPORTS, roles.viewAdminReports)
        }
        if (roles.unlockReadonlyBatches !== undefined) {
          commit(mutations.UNLOCK_READONLY_BATCHES, roles.unlockReadonlyBatches)
        }
      }
    }
  },
  mutations: {
    [mutations.UNASSIGN_OTHERS_FROM_BATCHES] (state, payload) {
      state.unassignOthersFromBatches = payload
    },
    [mutations.VIEW_ADMIN_REPORTS] (state, payload) {
      state.viewAdminReports = payload
    },
    [mutations.UNLOCK_READONLY_BATCHES] (state, payload) {
      state.unlockReadonlyBatches = payload
    }
  }
}
