"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrepareFormat = exports.PrepareCollectionStatus = exports.PrepareImageStatus = exports.PrepareType = void 0;
__exportStar(require("./job"), exports);
__exportStar(require("./topics"), exports);
var PrepareType;
(function (PrepareType) {
    PrepareType["PRINTQ"] = "PrintQ";
    PrepareType["JOB_DOWNLOAD"] = "Job Download";
})(PrepareType || (exports.PrepareType = PrepareType = {}));
var PrepareImageStatus;
(function (PrepareImageStatus) {
    PrepareImageStatus["ERROR"] = "ERROR";
    PrepareImageStatus["QUEUED"] = "QUEUED";
    PrepareImageStatus["RENDERING"] = "RENDERING";
    PrepareImageStatus["RENDERED"] = "RENDERED";
})(PrepareImageStatus || (exports.PrepareImageStatus = PrepareImageStatus = {}));
var PrepareCollectionStatus;
(function (PrepareCollectionStatus) {
    PrepareCollectionStatus["PREPARING"] = "PREPARING";
    PrepareCollectionStatus["ERRORED"] = "ERRORED";
    PrepareCollectionStatus["GETTING_DATA"] = "GETTING DATA";
    PrepareCollectionStatus["CREATING_ARTEFACT"] = "CREATING ARTEFACT";
    PrepareCollectionStatus["READY_FOR_PRINT"] = "READY FOR PRINT";
    PrepareCollectionStatus["COMPLETED"] = "COMPLETED";
})(PrepareCollectionStatus || (exports.PrepareCollectionStatus = PrepareCollectionStatus = {}));
var PrepareFormat;
(function (PrepareFormat) {
    PrepareFormat["NONE"] = "NONE";
    PrepareFormat["ZIP"] = "ZIP";
})(PrepareFormat || (exports.PrepareFormat = PrepareFormat = {}));
