import './firebase'
import './quasar'

import Vue from 'vue'

import App from './App.vue'
import { router } from './router'
import store from './store'
import { toTitleCase } from './util'

export const SLIDER_MIN = -125
export const SLIDER_MAX = 125

Vue.config.productionTip = false

document.title = 'QA Checker'

Vue.filter('toTitleCase', toTitleCase)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
