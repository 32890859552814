import firebase from 'firebase/app'
import { Module } from 'vuex'
import { RootState } from '..'
import { StatsDoc } from '@ht-lib/preproduction-models'
import { users } from '../../firebase'
import { roles, RolesState } from './roles'
import { settings, SettingsState } from './settings'
import { stats } from './stats'

export interface ProfileState {
  _ready: boolean
  uid: string
  displayName: string
  initials: string
  roles: RolesState
  settings: SettingsState
  stats: StatsDoc
}

enum mutations {
  READY = 'READY',
  PROFILE_LOADED = 'PROFILE_LOADED'
}

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  getters: {
    collection: state => state.uid
      ? users.collection(state.uid)
      : null
  },
  actions: {
    async init ({ commit, state, getters, dispatch }, payload: firebase.User) {
      commit(mutations.PROFILE_LOADED, payload)

      const detailsRef = getters.collection.doc('details') as firebase.firestore.DocumentReference
      const detailsWrite = detailsRef.set({
        uid: state.uid,
        name: state.displayName,
        lastLoggedIn: new Date().getTime()
      }, { merge: true })

      await Promise.all([
        detailsWrite,
        dispatch('settings/init'),
        dispatch('roles/init'),
        dispatch('stats/init')
      ])

      commit(mutations.READY)
    }
  },
  mutations: {
    [mutations.READY] (state) {
      state._ready = true
    },
    [mutations.PROFILE_LOADED] (state, payload: firebase.User) {
      state.uid = payload.uid
      state.displayName = payload.displayName || ''
      state.initials = state.displayName
        .split(' ')
        .reduce((prev, curr) => prev[0] + curr[0])
        .toUpperCase()
    }
  },
  modules: {
    settings,
    roles,
    stats
  }
}
