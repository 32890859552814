






import { Component, Vue } from "vue-property-decorator"
import firebase from "firebase/app"

@Component
export default class extends Vue {
  authStateInitialised = false

  created() {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        await this.$store.dispatch("profile/init", user)
      } else if (this.$route.name !== "auth") {
        this.$router.replace({ name: "auth" })
      }
      this.authStateInitialised = true
    })
  }
}
