import firebase from 'firebase/app'
import { Module } from 'vuex'
import { RootState } from '..'


export interface KeyBindings {
  [id: string]: string
  PreviousImage: string
  NextImage: string
  ShiftUp: string
  ShiftDown: string
  ShiftLeft: string
  ShiftRight: string
}
export interface SettingsState {
  showContrastBadgesToggle: boolean
  showOnlyPreferredImagesToggle: boolean
  keyBindings: KeyBindings
}

enum mutations {
  SETTINGS_LOADED = 'SETTINGS_LOADED',
  SHOW_CONTRAST_BADGES = 'SHOW_CONTRAST_BADGES',
  SHOW_ONLY_PREFERRED_IMAGES = 'SHOW_ONLY_PREFERRED_IMAGES',
  KEY_MAPPINGS = 'KEY_MAPPINGS'
}

export const settings: Module<SettingsState, RootState> = {
  namespaced: true,
  state: {
    showContrastBadgesToggle: true,
    showOnlyPreferredImagesToggle: false,
    keyBindings: {
      NextImage: 'z',
      PreviousImage: 'x',
      ShiftUp: 'ArrowUp',
      ShiftDown: 'ArrowDown',
      ShiftLeft: 'ArrowLeft',
      ShiftRight: 'ArrowRight'
    }
  },
  getters: {
    ref: (state, getters, rootState, rootGetters) => rootGetters['profile/collection'].doc('settings')
  },
  actions: {
    async init ({ commit, getters }) {
      const snapshot = await getters.ref.get() as firebase.firestore.DocumentSnapshot
      if (snapshot.exists) {
        const settings = snapshot.data() as Partial<SettingsState>
        if (settings.showContrastBadgesToggle !== undefined) {
          commit(mutations.SHOW_CONTRAST_BADGES, settings.showContrastBadgesToggle)
        }
        if (settings.showOnlyPreferredImagesToggle !== undefined) {
          commit(mutations.SHOW_ONLY_PREFERRED_IMAGES, settings.showOnlyPreferredImagesToggle)
        }
        if (settings.keyBindings !== undefined) {
          console.log(settings.keyBindings)
          commit(mutations.KEY_MAPPINGS, settings.keyBindings)
        }
      }
    },
    showContrastBadgesToggled ({ commit, getters }, payload: boolean) {
      commit(mutations.SHOW_CONTRAST_BADGES, payload)

      const ref = getters.ref as firebase.firestore.DocumentReference
      return ref.set({ showContrastBadgesToggle: payload }, { merge: true })
    },
    showOnlyPreferredImagesToggled ({ commit, getters }, payload: boolean) {
      commit(mutations.SHOW_ONLY_PREFERRED_IMAGES, payload)

      const ref = getters.ref as firebase.firestore.DocumentReference
      return ref.set({ showOnlyPreferredImagesToggle: payload }, { merge: true })
    },
    keyMappingsUpdated ({ commit, getters }, payload: { binding: string; key: string }) {
      const newBindings = this.state.profile.settings.keyBindings
      newBindings[payload.binding] = payload.key
      commit(mutations.KEY_MAPPINGS, newBindings)

      const ref = getters.ref as firebase.firestore.DocumentReference
      const data: Partial<SettingsState> = {
        keyBindings: newBindings
      }
      return ref.set(data, { merge: true })
    }
  },
  mutations: {
    [mutations.SHOW_CONTRAST_BADGES] (state, payload) {
      state.showContrastBadgesToggle = payload
    },
    [mutations.SHOW_ONLY_PREFERRED_IMAGES] (state, payload) {
      state.showOnlyPreferredImagesToggle = payload
    },
    [mutations.KEY_MAPPINGS] (state, payload) {
      state.keyBindings = payload
    }
  }
}
