"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isImageMarketRef = exports.isImageMarketID = void 0;
function isImageMarketID(value) {
    return 'market' in value && 'id' in value;
}
exports.isImageMarketID = isImageMarketID;
function isImageMarketRef(value) {
    return 'market' in value && 'ref' in value;
}
exports.isImageMarketRef = isImageMarketRef;
