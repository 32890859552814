"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BatchState = void 0;
var BatchState;
(function (BatchState) {
    BatchState[BatchState["ERROR"] = -1] = "ERROR";
    BatchState[BatchState["READY"] = 0] = "READY";
    BatchState[BatchState["WAITINGIMAGES"] = 5] = "WAITINGIMAGES";
    BatchState[BatchState["WAITING_EDITING"] = 6] = "WAITING_EDITING";
    BatchState[BatchState["ONHOLD"] = 8] = "ONHOLD";
    BatchState[BatchState["INPROGRESS"] = 10] = "INPROGRESS";
    BatchState[BatchState["COMPLETE"] = 15] = "COMPLETE";
})(BatchState || (exports.BatchState = BatchState = {}));
