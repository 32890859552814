import axios from 'axios'

import { ImageEntry } from '@ht-lib/accounts-models'

export interface ImageWithMeta {
  blob: Blob
  el: HTMLImageElement
  meta: ImageEntry
  url: string
}

export enum ErrorTypes {
  ImageNotFound
}

export async function preloadImageXHR (url: string): Promise<Omit<ImageWithMeta, 'meta'>> {
  try {
    const res = await axios.get(url, { responseType: 'blob' })
    if (res.status === 204) {
      throw ErrorTypes.ImageNotFound
    }
    return {
      blob: res.data,
      el: await imageFromBlob(res.data),
      url
    }
  } catch (e) {
    console.error('Error getting image with url: ', url)
    throw ErrorTypes.ImageNotFound
  }
}

export function imageFromBlob (blob: Blob): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = () => reject(new Error('There was a problem loading the blob into an image'))
    img.crossOrigin = 'anonymous'
    img.src = URL.createObjectURL(blob)
  })
}

export function toTitleCase (str: string): string {
  return str // Regex is probably better
    .split(' ')
    .map(x => x[0].toUpperCase() + x.slice(1))
    .join(' ')
}
