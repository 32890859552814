import store from '@/store'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

import { canQA, ErrorTypes, getBatch, setBatchInProgress } from './batchList'

Vue.use(VueRouter)

const routes: RouteConfig[] = [
  {
    path: '/login',
    name: 'auth',
    component: () => import('@/pages/Auth.vue')
  },
  {
    path: '/',
    component: () => import('@/layouts/Default.vue'),
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('@/pages/Home.vue')
      },
      {
        path: '/search/:id',
        name: 'search',
        component: () => import('@/pages/Home.vue')
      },
      {
        path: '/account/:account/job/:job',
        name: 'job',
        props: true,
        component: () => import('@/pages/Job.vue')
      },
      {
        path: '/batch/:batch',
        name: 'batch',
        props: true,
        component: () => import('@/pages/Batch.vue'),
        async beforeEnter (to, from, next) {
          let batchInFireStore = true
          let readOnly = false
          const batchId = to.params.batch.trim() as unknown as number

          try {
            await setBatchInProgress(batchId)
          } catch (e) {
            if (e === ErrorTypes.BatchNotFound) {
              batchInFireStore = false
            } else {
              readOnly = !canQA(await getBatch(batchId))
            }
          }

          to.params.batchInFireStore = batchInFireStore ? '1' : '0'
          to.params.readOnly = readOnly ? '1' : '0'
          return next(to.params)
        }
      }
    ]
  }
]

export const router = new VueRouter({
  mode: 'history',
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.name === 'auth') return next()

  while (!store.state.profile._ready) {
    await new Promise(resolve => setTimeout(resolve, 250))
  }

  next()
})
