import { Batch, BatchState } from '@ht-lib/preproduction-models'
import store from '@/store'
import firebase from 'firebase/app'

import { batches, database } from './firebase'

type DocumentSnapshot = firebase.firestore.DocumentSnapshot

export enum ErrorTypes {
  BatchNotFound = 'BatchNotFound',
  BatchOperationDenied = 'BatchOperationDenied'
}

export function getBatchStateText (state: BatchState): string {
  switch (state) {
    case BatchState.READY:
      return 'Ready'
    case BatchState.INPROGRESS:
      return 'In Progress'
    case BatchState.WAITING_EDITING:
      return 'With Image Editing'
    case BatchState.WAITINGIMAGES:
      return 'Waiting Images'
    case BatchState.ONHOLD:
      return 'On Hold'
    case BatchState.COMPLETE:
      return 'Complete'
    case BatchState.ERROR:
      return 'Error'
    default:
      throw Error('Unhandled BatchState')
  }
}

export async function getBatch (id: number): Promise<Batch> {
  return (await batches.doc(id.toString()).get()).data() as Batch
}

export function canQA (batch: Batch): boolean {
  return (isSameUser(batch) || !hasUser(batch)) && batch.state !== BatchState.WAITING_EDITING
}

export function canUnlockReadonly (): boolean {
  return store.state.profile.roles.unlockReadonlyBatches
}

type Test = (currentBatch: Batch) => boolean

function inProgress (currentBatch: Batch): boolean {
  return currentBatch.state === BatchState.INPROGRESS
}

function isComplete (currentBatch: Batch): boolean {
  return currentBatch.state === BatchState.COMPLETE
}

function hasUser (currentBatch: Batch): boolean {
  return currentBatch.user !== undefined
}

function isSameUser (currentBatch: Batch): boolean {
  return currentBatch.user !== undefined && currentBatch.user.uid === store.state.profile.uid
}

export async function unlockReadonly (batchId: number): Promise<void> {
  const { uid, displayName } = store.state.profile
  if (canUnlockReadonly()) {
    const test: Test = (): boolean => true // Skip test
    const update: Partial<Batch> = {
      user: {
        uid,
        name: displayName
      },
      state: BatchState.INPROGRESS
    }

    await transactUpdateBatch(batchId, update, test)
  }
}

export function setBatchInProgress (batchId: number): Promise<DocumentSnapshot> {
  const { uid, displayName } = store.state.profile
  const test: Test = (currentBatch): boolean => !isComplete(currentBatch) && (!hasUser(currentBatch) || isSameUser(currentBatch))
  const update: Partial<Batch> = {
    user: {
      uid,
      name: displayName
    },
    state: BatchState.INPROGRESS
  }

  return transactUpdateBatch(batchId, update, test)
}

export function unassignUser (batchId: number): Promise<void> {
  const test: Test = (currentBatch): boolean => hasUser(currentBatch)
  const update: Partial<Batch> = {
    user: firebase.firestore.FieldValue.delete() as unknown as undefined
  }

  const ref = batches.doc(batchId.toString())
  return database.runTransaction((transaction) => transaction
    .get(ref)
    .then(snapshot => {
      const batch = testBatch(snapshot, test)
      if (batch.state === BatchState.INPROGRESS) {
        update.state = BatchState.READY
      }

      transaction.update(ref, update)
    }))
}

export function setBatchReady (batchId: number): Promise<DocumentSnapshot> {
  const test: Test = (currentBatch): boolean => inProgress(currentBatch) && isSameUser(currentBatch)
  const update: Partial<Batch> = {
    user: firebase.firestore.FieldValue.delete() as unknown as undefined,
    state: BatchState.READY
  }

  return transactUpdateBatch(batchId, update, test)
}

export function setBatchErrored (batchId: number): Promise<DocumentSnapshot> {
  const test: Test = (currentBatch): boolean => !isComplete(currentBatch)
  const update: Partial<Batch> = { state: BatchState.ERROR }

  return transactUpdateBatch(batchId, update, test)
}

export function setBatchAwaitingImages (batchId: number): Promise<DocumentSnapshot> {
  const test: Test = (currentBatch): boolean => !isComplete(currentBatch)
  const update: Partial<Batch> = { state: BatchState.WAITINGIMAGES }

  return transactUpdateBatch(batchId, update, test)
}

export function setBatchComplete (batchId: number): Promise<DocumentSnapshot> {
  const test: Test = (currentBatch): boolean => isSameUser(currentBatch)
  const update: Partial<Batch> = { state: BatchState.COMPLETE }

  return transactUpdateBatch(batchId, update, test)
}

export function setBatchOnHold (batchId: number, timeInQA: number): Promise<DocumentSnapshot> {
  const test: Test = (currentBatch): boolean => inProgress(currentBatch) && isSameUser(currentBatch)
  const update: Partial<Batch> = { state: BatchState.ONHOLD, timeCache: firebase.firestore.FieldValue.increment(timeInQA) as unknown as number }

  return transactUpdateBatch(batchId, update, test)
}

function testBatch (snapshot: DocumentSnapshot, test: Test): Batch {
  if (!snapshot.exists) {
    throw ErrorTypes.BatchNotFound
  }

  const batch = snapshot.data() as Batch
  const testResult = test(batch)
  if (testResult === false) {
    console.warn('TEST FAILED', test, testResult)
    throw ErrorTypes.BatchOperationDenied
  }

  return batch
}

function transactUpdateBatch (batchId: number, update: Partial<Batch>, test: Test): Promise<DocumentSnapshot> {
  const ref = batches.doc(batchId.toString())
  return database.runTransaction((transaction) => transaction
    .get(ref)
    .then(snapshot => {
      testBatch(snapshot, test)
      transaction.set(ref, update, { merge: true })
      return snapshot
    }))
}
