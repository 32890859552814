import { Stat, StatsDoc as StatsState } from '@ht-lib/preproduction-models'
import firebase from 'firebase/app'
import Vue from 'vue'
import { Module } from 'vuex'
import { RootState } from '..'


enum mutations {
  STATS_LOADED = 'STATS_LOADED',
}

const initialState: Stat = {
  batches: 0,
  images: 0
}

export const stats: Module<StatsState, RootState> = {
  namespaced: true,
  state: {
    daily: initialState,
    weekly: initialState,
    monthly: initialState,
    yearly: initialState,
    lifetime: initialState
  },
  getters: {
    ref: (state, getters, rootState, rootGetters) => rootGetters['profile/collection'].doc('stats')
  },
  actions: {
    init ({ commit, getters }) {
      // const snapshot = await getters.ref.get() as firebase.firestore.DocumentSnapshot
      const ref = getters.ref as firebase.firestore.DocumentReference
      ref.onSnapshot(snapshot => {
        if (snapshot.exists) {
          const stats = snapshot.data() as StatsState
          commit(mutations.STATS_LOADED, stats)
        }
      })
    }
  },
  mutations: {
    [mutations.STATS_LOADED] (state, payload: StatsState) {
      Vue.set(state, 'daily', payload.daily)
      Vue.set(state, 'weekly', payload.weekly)
      Vue.set(state, 'monthly', payload.monthly)
      Vue.set(state, 'yearly', payload.yearly)
      Vue.set(state, 'lifetime', payload.lifetime)
    }
  }
}
