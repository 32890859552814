import { profile, ProfileState } from '@/store/profile'
import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'

import { app } from '../firebase'

Vue.use(Vuex)

export interface RootState {
  version: string
  newVersionAvailable: boolean
  profile: ProfileState
}

enum mutations {
  NEW_VERSION = 'NEW_VERSION',
}

const state: Partial<RootState> = {
  version: process.env.VUE_APP_VERSION_STRING as string,
  newVersionAvailable: false
}

const store: StoreOptions<RootState> = {
  state: state as RootState,
  actions: {
    init ({ state, commit }) {
      app.onSnapshot(snapshot => {
        const version: { tagName: string } | undefined = snapshot.get('version')
        if (version && version.tagName !== state.version) {
          commit(mutations.NEW_VERSION, version)
        }
      })
    }
  },
  mutations: {
    [mutations.NEW_VERSION] (state, payload) {
      state.version = payload
      state.newVersionAvailable = false
    }
  },
  modules: {
    profile
  }
}

export default new Vuex.Store<RootState>(store)
